<template>
  <div class="min-h-screen">
    <div v-if="material">
      <div class="sela-container mt-4">
        <div
          class="flex flex-col md:flex-row rounded-md overflow-hidden shadow bg-bg_alt_color mb-5"
        >
          <MaterialsShowImageSlider
            :key="`ImageSlider-${material.id}`"
            :material="material"
          />
          <MaterialsShowMeta
            :material="material"
            @add-addons-attribute="addAddonsAttributesToMaterial"
            @refresh-page="updateMaterials"
          />
        </div>
        <MaterialsShowShareNetwork :material="material" />
        <MaterialsShowListRates
          v-if="!isDomain"
          :key="keyRatesReview"
          :material="material"
        />

        <HomeOffersGroupSlider :query="{ 'material_ids[]': [material.id] }" />

        <RikazGrid
          v-if="likeMaterials?.length"
          name="similar"
          class="mb-5"
          :items="likeMaterials"
          margin="ltr:-ml-3.5 rtl:-mr-3.5"
        >
          <template #title>
            <div class="flex justify-center my-2">
              <div class="sela-title">{{ t('like_materials') }}</div>
            </div>
          </template>
          <template #card="{ forKey, data }">
            <!-- TODO: Support Material Offer & Store From Server -->
            <MaterialsCard
              :key="`similar-grid-item-${forKey}`"
              class-card="w-[8rem] xs:w-[9rem] sm:w-[13.5rem]"
              class="transition-all duration-500 transform hover:scale-105"
              :material-props="(data as Material)"
            />
          </template>
        </RikazGrid>
        <HomeAdSecondarySwiper :ads="{ items: ads, error: errorAds }" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FetchError } from 'ofetch'
import type { Material, Ad } from '~~/composables/useMenuModel'

const { materialModel, adModel } = useMenuModel()
const isDomain = useIsDomain()
const { url } = useDomainHost()
const { store } = useDomainState()
const emit = defineEmits(['update-material'])
const material = ref<Material | null>(null)
let error = ref<FetchError | null>(null)
const keyRatesReview = ref<number>(Math.random())
const currentBranchId = useBranchId()
const { t } = useI18n()

const materialTemp = await fetchMaterial()
if (materialTemp.value) {
  material.value = materialModel(materialTemp.value)
  emit('update-material', material.value)
}

async function fetchMaterial(newIdMaterial?: number) {
  const { data: materialTemp, error: errorTemp } = await useNuxtApiFetch(
    useMenuModel().isAuthLoggedIn()
      ? '/user/materials/material'
      : '/laravel/material',
    {
      query: {
        id: newIdMaterial || useRoute().params.id,
        branch_id: currentBranchId,
        domain: useDomainHeader()
      },

      transform: (data: SuccessResponse<Material>) => {
        return data.data
      }
    }
  )
  error = errorTemp
  return materialTemp
}

async function updateMaterials(event: any) {
  const materialTemp = await fetchMaterial(event)
  if (materialTemp.value) {
    material.value = materialModel(materialTemp.value)
    emit('update-material', material.value)
  }
  keyRatesReview.value = Math.random()
}
const { data: ads, error: errorAds } = await useLazyApiFetch(
  `/announcements-store/${material.value?.store_id}`,
  {
    query: { branch_id: currentBranchId },
    transform: (
      data: SuccessResponse<{
        announcementsStore: Ad[]
        announcements: Ad[]
      }>
    ) => {
      return [
        ...(isDomain ? [] : data.data.announcements.map(adModel)),
        ...data.data.announcementsStore.map(adModel)
      ]
    }
  }
)
if (ads.value?.length) {
  ads.value = ads.value.map(adModel)
}
const likeMaterials = computed(() => {
  return material.value?.likeMaterials?.slice(0, 4)
})
useApiError(error.value)

const addAddonsAttributesToMaterial = (extraAttribute: {
  name: string
  desc: string
  price: string
}) => {
  const index =
    material.value?.addons?.findIndex(
      (el) => el.name === extraAttribute.name && el.desc === extraAttribute.desc
    ) || -1
  let attributes = []
  if (index >= 0) {
    attributes =
      material.value?.addons?.filter((el) => el.name !== extraAttribute.name) ||
      []
  } else {
    attributes = [...(material.value?.addons || []), extraAttribute]
  }
  material.value = {
    ...material.value,
    addons: attributes
  } as any
}
useServerSeoMeta(
  useOgMeta(
    url + '/materials/' + material.value?.id,
    material.value?.name,
    material.value?.summary,
    material.value?.thumb
  )
)

useHead({
  title: material.value?.name,
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: material.value?.summary
    }
  ],
  link: [useSeoCanonical(url + '/materials/' + material.value?.id)],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, isDomain ? store.value?.name : t('home'), url),
      useSeoBreadcrumbItem(
        2,
        material.value?.name,
        url + '/materials/' + material.value?.id
      )
    ]),
    material.value ? useSeoProduct(material.value) : {}
  ]
})
</script>
