<template>
  <div
    v-if="rates.length"
    class="flex flex-wrap justify-center rounded-md shadow bg-bg_alt_color mb-5 px-5 lg:px-10"
  >
    <div class="w-36 ml-5 lg:ml-10 my-5 lg:my-10">
      <div class="text-lg text-text_color font-medium">{{ t('reviews') }}</div>

      <div class="bg-rate-light p-2 my-5">
        <div class="text-2xl text-rate text-center font-semibold">
          {{ material.average_rate || 0 }}
        </div>
        <div class="flex justify-center">
          <div v-for="star in 5" :key="`show-material-star-${star}`">
            <v-icon
              v-if="star <= (material.average_rate || 0)"
              icon="fa: fa-solid fa-star"
              :size="12"
              class="mx-px text-rate"
            />
            <v-icon
              v-else
              icon="fa: fa-regular fa-star"
              :size="12"
              class="mx-px text-sela-light"
            />
          </div>
        </div>
        <div class="text-center text-lg my-1">
          {{ t('rate_count', { count: material.count }) }}
        </div>
      </div>
    </div>
    <div class="flex-auto h-72 overflow-y-auto">
      <div
        v-for="(item, index) in rates"
        :key="`review-card-${index}`"
        class="border-2 rounded-md p-5 mb-5 mt-2"
      >
        <MaterialsRateCard :rate-props="item" />
      </div>
      <ClientOnly>
        <button
          v-if="manual && rates.length >= itemsPerPage"
          :aria-label="t('view_more', { of: t('of'), name: t('materials') })"
          class="block mx-auto"
          :disabled="loadState == 'done'"
          @click="manualFetch"
        >
          <div
            v-if="loadState == 'done'"
            class="opacity-80 classButton cursor-default"
          >
            {{ t('loaded') }}
          </div>
          <div
            v-else
            class="hover:bg-button_color classButton text-text_alt_color bg-nav_color"
          >
            {{ t('load_more') }}
          </div>
        </button>

        <SelaInfiniteScroll
          v-else
          class="my-2"
          :infinite-id="infiniteId"
          @load-more="loadMore"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import type { LoadState } from '~/composables/useInfiniteScroll'
import type { Rate, Material } from '~~/composables/useMenuModel'

const props = withDefaults(
  defineProps<{
    itemsPerPage?: number
    manual?: boolean
    material: Material
  }>(),
  {
    itemsPerPage: 5,
    manual: true
  }
)

const toast = useToast()
const rates = ref<Rate[]>([])
const loadState = ref<LoadState>()

const { t } = useI18n()

const { infiniteId, scrollFetch, loadMore /* , reset */ } = useInfiniteScroll(
  0,
  props.itemsPerPage,
  fetchRates,
  appendRates,
  clearRates
)

await manualFetch()

async function fetchRates(state: ScrollState) {
  try {
    const url = `/material/${props.material?.id}/getRatings`
    const { data, error } = await useApiFetch<ApiResponse<[]>>(url, {
      query: {
        offset: `${state.offset}`,
        limit: `${state.limit}`
      }
    })

    if (error?.value) {
      throw error.value
    }

    return data.value
  } catch (error) {
    if (process.client) {
      toast.error(t('fetch_failed', { data: t('rates') }), { timeout: 1000 })
    }

    if ((error as any)?.data?.data) {
      return (error as any).data as ApiResponse<[]>
    } else {
      return {
        data: [],
        message: t('fetch_failed', { data: t('rates') })
      } as ApiResponse<[]>
    }
  }
}

function appendRates(data: []) {
  rates.value = [...rates.value, ...data]
}

function clearRates() {
  rates.value = []

  loadState.value = 'load'
}

async function manualFetch() {
  loadState.value = await scrollFetch()
}
</script>

<style scoped>
.rate-bar {
  @apply flex items-center;
}
.rate-progress {
  @apply bg-rate h-2 rounded-full mr-auto;
}
.classButton {
  @apply border border-button_color mx-auto  rounded-lg px-4 pt-2 pb-1 my-2;
}
</style>
